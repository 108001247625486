<template>
  <div class="form-container" :class="containerClass">
    <label
      v-if="label"
      class="form-input-label"
      :class="[
        statusInput,
        customLabelClass,
        requiredInput && 'form-input-label-required',
      ]"
    >
      <slot name="label" v-bind="{ label, tooltip }">
        {{ label }}
        <HccInfoTooltip
          v-if="tooltip"
          :text="tooltip"
          :custom-class="'icon-info__table'"
        />
      </slot>
    </label>
    <div
      class="form-control"
      :class="[
        size,
        statusInput,
        customContainerClass,
        borderLess && 'form-control-border-less',
      ]"
    >
      <button
        v-if="iconInputStart"
        type="button"
        class="icon"
        tabindex="-1"
        :class="{ disabled: disabled }"
        :disabled="disabled"
      >
        <Icon :icon="iconInputStart" class="icon-md" />
      </button>
      <input
        ref="hccInput"
        :data-test-id="dataTestId"
        :class="[statusInput, customClass]"
        class="input"
        :type="inputType"
        :readonly="readonly"
        :disabled="disabled"
        :data-test-value="modelValue"
        :value="modelValue"
        :maxlength="counterCharacters ? counterCharacters : null"
        v-bind="$attrs"
        @input="updateModelValue"
      />
      <div v-if="textIcon" class="icon">
        <p class="text-icon">{{ textIcon }}</p>
      </div>
      <template v-for="action in actions" :key="action.icon">
        <button
          class="icon icon-action-right-icon"
          tabindex="-1"
          :class="[
            action.cursor,
            action.customClass,
            { disabled: disabled || action.disabled },
          ]"
          :style="{ color: `var(${action.color})` }"
          :type="action.buttonType || 'button'"
          :disabled="disabled || action.disabled"
          :data-test-id="action.dataTestId || 'actionButton'"
          @click="actionIcon(action)"
        >
          <Icon
            class="icon-md"
            :class="action.class"
            :icon="
              action.type === 'showPassword' && !showPassword
                ? 'mdi:eye-off'
                : action.icon
            "
          />
        </button>
      </template>
    </div>
    <div
      v-if="helpText || counterCharacters"
      class="form-hint"
      :class="statusInput"
    >
      <p v-if="helpText">
        {{ helpText }}
      </p>
      <div
        v-if="counterCharacters"
        class="counter-box"
        :class="{ 'counter-characters': counterCharacterStyle }"
      >
        {{ modelValue.length }} / {{ counterCharacters }}
      </div>
    </div>
    <div v-if="error && hasError" class="form-input__error">
      <p
        v-for="(error, index) in hasError"
        :key="index"
        :data-test-id="`${dataTestId || 'input'}ErrorLabel`"
      >
        {{ error }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { Icon } from '@iconify/vue';
import HccInfoTooltip from '../HccInfoTooltip/HccInfoTooltip.vue';

const props = defineProps({
  iconInputStart: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: 'text',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'sm',
  },
  error: {
    type: Boolean,
    default: false,
  },
  errorMessage: {
    type: String,
  },
  hasError: {
    type: [String, Object, Array],
  },
  borderLess: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: Boolean,
    default: false,
  },
  textIcon: {
    type: String,
  },
  modelValue: {
    required: true,
  },
  label: {
    type: String,
  },
  customClass: {
    type: [String, Object, Array],
  },
  customContainerClass: {
    type: [String, Object, Array],
  },
  requiredInput: {
    type: Boolean,
    default: false,
  },
  helpText: {
    type: String,
    default: null,
  },
  tooltip: {
    type: String,
    default: null,
  },
  dataTestId: {
    type: String,
    default: null,
  },
  actions: {
    type: Array,
    default: () => [],
  },
  counterCharacters: {
    type: String,
    default: null,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  containerClass: {
    type: [String, Array],
    default: null,
  },
  customLabelClass: {
    type: [String, Array],
    default: null,
  },
  autofocus: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

const hccInput = ref(null);
const showPassword = ref(true);
const inputType = ref(props.type);
const counterCharacterStyle = ref('');

const statusInput = computed(() => ({
  disabled: props.disabled,
  error: props.error,
  'read-only': props.readonly,
}));

const updateModelValue = (event) => {
  emit('update:modelValue', event.target.value);
};

const toggleShowPassword = () => {
  showPassword.value = !showPassword.value;
  inputType.value = showPassword.value ? 'password' : 'text';
};

const actionIcon = (action) => {
  if (action.type === 'showPassword') {
    toggleShowPassword();
    return;
  }
  action.onAction && action.onAction();
};

watch(
  () => props.modelValue,
  (newVal) => {
    if (props.counterCharacters) {
      counterCharacterStyle.value =
        newVal.length >= props.counterCharacters ? 'counter-characters' : '';
    }
  },
);
onMounted(() => {
  if (props.autofocus) {
    hccInput.value.focus();
  }
});
</script>
